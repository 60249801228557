<template>
  <el-menu mode="horizontal" :ellipsis="false">
    <el-menu-item index="1">
      <a href="/">
        <img style="width: 64px" src="/images/zhaoyazhao.webp" alt="ZhaoyaZhao Logo" />
      </a>
    </el-menu-item>
    <el-menu-item v-if="!logedin" index="2" @click="login">登录</el-menu-item>
    <el-menu-item v-if="!logedin" index="3" @click="register">注册</el-menu-item>
    <el-sub-menu v-if="logedin" index="4">
      <template #title>欢迎, {{ nickname }}</template>
      <el-menu-item index="4-1" @click="login">切换帐户</el-menu-item>
      <el-menu-item index="4-2" @click="register">注册新帐户</el-menu-item>
      <el-menu-item index="4-3" @click="manageAccount">帐户管理</el-menu-item>
      <el-menu-item index="4-4" @click="logout">退出</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="5" @click="toHome">主页</el-menu-item>
    <el-menu-item index="6" @click="toMymessage">
      <el-badge v-if="unread!=0 && unread!=null" :value="unread" :max="99" class="item">我的消息
      </el-badge>
      <div v-else>我的消息</div>
    </el-menu-item>
    <el-menu-item index="7" @click="toMypost">我的发布</el-menu-item>
    <el-menu-item index="8">
      <el-button type="primary" @click="post">发布消息</el-button>
    </el-menu-item>
  </el-menu>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import md5 from '../../public/javascripts/md5.js'

const router = useRouter()
const logedin = ref(false)
const userId = ref(0)
const nickname = ref('')
const email = ref('')
const portrait = ref('')
const unread = ref(0)

const post = () => {
  router.push('/login?auto=1&addkey=1&redirect=/post#placeholder:1')
}

const login = () => {
  router.push('/login?auto=0&addkey=0&redirect=/')
}

const register = () => {
  router.push('/register')
}

const logout = () => {
  localStorage.removeItem('email')
  localStorage.removeItem('pwd')
  logedin.value = false
  email.value = ''
  nickname.value = ''
}

const toHome = () => {
  router.push('/')
}

const manageAccount = () => {
  router.push('/manageAccount?id=' + userId.value)
}

const toMymessage = () => {
  /* if (!logedin.value) {
    router.push('/login?auto=0&addkey=0&redirect=/')
  } else {
    router.push('/mymessage')
  } */
  router.push('/login?auto=1&addkey=1&redirect=/mymessage#placeholder=1')
}

const toMypost = () => {
  /* if (!logedin.value) {
    router.push('/login?auto=0&addkey=0&redirect=/')
  } else {
    router.push('/mypost?id=' + userId.value)
  } */

  router.push('/login?auto=1&addkey=1&redirect=/mypost#placeholder:1')
}

onMounted(async () => {
  const _email = localStorage.getItem('email')
  const pwd = localStorage.getItem('pwd')
  if (_email != null && _email.length > 0 && pwd != null && pwd.length > 0) {
    await axios
      .post('/api/login', {
        email: _email,
        pwd: md5(pwd)
      })
      .then((res) => {
        if (res.data.err === 0) {
          localStorage.token = res.data.data.token
          localStorage.userId = res.data.data.userId
          userId.value = res.data.data.userId
          email.value = _email
          logedin.value = true
          nickname.value = res.data.data.nickname
          portrait.value = '/dist/uploads/' + res.data.data.portrait
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // check if there is/are new message(s) for me
  if (logedin.value) {
    axios.get('/api/getUnread?userId=' + userId.value)
      .then((res) => {
        if (res.data.length >= 0) {
          unread.value = res.data[0].unread
        } else {
          unread.value = 0
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
})

</script>

<style scoped>
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}
</style>

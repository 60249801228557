<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <HeaderView />
      </el-header>
      <el-main>
        <div class="mb-2 ml-4">
          我要 <br />
          <br />

          <el-select v-if="radio1 == '1'"
            v-model="service"
            placeholder="Select"
            size="large"
            style="width: 240px"
            @change = "selectService"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

          <el-segmented v-model="service" :options="options" @change="onChange">
            <template #default="{ item }">
              <font-awesome-icon :icon="item.icon" size="2x"/>
              <div>{{ item.label }}</div>
            </template>
          </el-segmented>

        </div>

        <!--el-button v-if="radio1 == '1' && service != '0'" type="primary"
          >找呀找</el-button
      -->
      </el-main>
      <!--el-footer>Footer</el-footer-->
    </el-container>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import HeaderView from '@/components/HeaderView.vue'

const radio1 = ref('0')
const service = ref('请选择')
const router = useRouter()
const options = [
  {
    value: '1',
    label: '租房',
    icon: ['fas', 'house']
  },
  {
    value: '2',
    label: '搭车',
    icon: ['fas', 'car']
  },
  {
    value: '3',
    label: '机场接送',
    icon: ['fas', 'plane']
  },
  {
    value: '4',
    label: '带东西',
    icon: ['fas', 'person-walking-luggage']
  },
  {
    value: '5',
    label: '练车陪练',
    icon: ['fas', 'car-side']
  },
  {
    value: '6',
    label: '搬家',
    icon: ['fas', 'truck']
  },
  {
    value: '7',
    label: '换币',
    icon: ['fas', 'money-bill-transfer']
  },
  {
    value: '8',
    label: '二手货',
    icon: ['fas', 'arrow-right-arrow-left']
  },
  {
    value: '9',
    label: '信息咨询与分享',
    icon: ['fas', 'circle-question']
  }
]

const selectService = (par) => {
  console.log(par)
  if (par === '1') {
    router.push('/rent')
  } else if (par === '2') {
    router.push('/ride')
  }
}

const onChange = (par) => {
  console.log(par)
  if (par === '1') {
    router.push('/rentResult')
  } else if (par === '2') {
    router.push('/rideResult')
  } else if (par === '3') {
    router.push('/airportResults')
  } else if (par === '4') {
    router.push('/bringResults')
  } else if (par === '5') {
    router.push('/coachResults')
  } else if (par === '6') {
    router.push('/moveResults')
  } else if (par === '7') {
    router.push('/exchangeResults')
  } else if (par === '8') {
    router.push('/seconduseResults')
  } else if (par === '9') {
    router.push('/articles')
  }
}
</script>

<style scoped>
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}
</style>
